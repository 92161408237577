import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import ChooseFile from 'components/Shared/ChooseFile';
import DetailsInformationCard from 'components/Shared/DetailsInformationCard';

import './styles.scss';

const DnisFoodSafetyDetails = ({ dnisCertifications, organicData, isCurrentUserCreator }) => {
  const globalGapCertificates = dnisCertifications.find(
    (cert) => cert.label === 'Global GAP Certificate',
  )?.data;
  const ppecbCertificates = dnisCertifications.find(
    (cert) => cert.label === 'PPECB Certificate',
  )?.data;
  const gaccCertificates = dnisCertifications.find((cert) => cert.label === 'GACC Certificate')?.data;
  const mrlResults = dnisCertifications.find((cert) => cert.label === 'MRL Results')?.data;
  const esgCertificates = dnisCertifications.find((cert) => cert.label === 'ESG Certificate')?.data;

  return (
    <div className="dnis-food-safety-details">
      <div className="dnis-food-safety-details__food_title">
        {t('bidding_process.food_safety_details')}
      </div>

      <div>
        <DetailsInformationCard
          cardHeader={t('bidding_process.organic')}
          detailsData={organicData}
        />
      </div>

      {isCurrentUserCreator &&
        (ppecbCertificates[0]?.fileName || globalGapCertificates[0]?.fileName) && (
          <div>
            {ppecbCertificates[0]?.fileName && (
              <div className="test-certificates">
                <div className="test-certificates__header">PPECB Certificates</div>
                <div className="margin-t-16px padding-b-16px test-certificates__form">
                  {ppecbCertificates.map((certificate) => (
                    <ChooseFile
                      fileName={certificate.fileName?.name}
                      fileUrl={certificate.fileName?.url}
                      label={t('bidding_process.ppecb_certificate')}
                      hint={t('profile.file.upload')}
                      isEditable={false}
                    />
                  ))}
                </div>
              </div>
            )}
            {globalGapCertificates[0]?.fileName && (
              <div className="test-certificates">
                <div className="test-certificates__header">Global GAP Certificates</div>
                <div className="margin-t-16px padding-b-16px test-certificates__form">
                  {globalGapCertificates.map((certificate) => (
                    <ChooseFile
                      fileName={certificate.fileName?.name}
                      fileUrl={certificate.fileName?.url}
                      label={t('bidding_process.global_gap_certificate')}
                      hint={t('profile.file.upload')}
                      isEditable={false}
                    />
                  ))}
                </div>
              </div>
            )}
            {gaccCertificates[0]?.fileName && (
              <div className="test-certificates">
                <div className="test-certificates__header">GACC Certificates</div>
                <div className="margin-t-16px padding-b-16px test-certificates__form">
                  {gaccCertificates.map((certificate) => (
                    <ChooseFile
                      fileName={certificate.fileName?.name}
                      fileUrl={certificate.fileName?.url}
                      label={t('bidding_process.gacc_certificate')}
                      hint={t('profile.file.upload')}
                      isEditable={false}
                    />
                  ))}
                </div>
              </div>
            )}
            {mrlResults[0]?.fileName && (
              <div className="test-certificates">
                <div className="test-certificates__header">MRL Results</div>
                <div className="margin-t-16px padding-b-16px test-certificates__form">
                  {mrlResults.map((certificate) => (
                    <ChooseFile
                      fileName={certificate.fileName?.name}
                      fileUrl={certificate.fileName?.url}
                      label={t('bidding_process.mrl_results')}
                      hint={t('profile.file.upload')}
                      isEditable={false}
                    />
                  ))}
                </div>
              </div>
            )}
            {esgCertificates[0]?.fileName && (
              <div className="test-certificates">
                <div className="test-certificates__header">ESG Certificates</div>
                <div className="margin-t-16px padding-b-16px test-certificates__form">
                  {esgCertificates.map((certificate) => (
                    <ChooseFile
                      fileName={certificate.fileName?.name}
                      fileUrl={certificate.fileName?.url}
                      label={t('bidding_process.esg_certificate')}
                      hint={t('profile.file.upload')}
                      isEditable={false}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
    </div>
  );
};

DnisFoodSafetyDetails.propTypes = {
  dnisCertifications: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  organicData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      data: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isCurrentUserCreator: PropTypes.bool.isRequired,
};

export default DnisFoodSafetyDetails;
