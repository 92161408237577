import React, { useContext } from 'react';
import { t } from 'i18next';
import ListingDetailsContext from 'components/BiddingProcess/ListingDetails/ListingDetailsContext';
import DetailsInformationCard from 'components/Shared/DetailsInformationCard';
import './styles.scss';

const OrderFoodSafetyDetails = () => {
  const {
    productInformation,
  } = useContext(ListingDetailsContext);

  const {
    globalGapCertificate,
    ppecbCertificate,
    gaccCertificate,
    mrlResults,
    esgCertificate,
    organic,
    pasteurized,
  } = productInformation;

  if (!(
    globalGapCertificate ||
    ppecbCertificate ||
    gaccCertificate ||
    mrlResults ||
    esgCertificate ||
    organic ||
    pasteurized)
  ) {
    return ""
  }

  return (
    <div className="food-safety-details">
      <div className="food-safety-details__title">{t('bidding_process.food_safety_details')}</div>
      <div className="food-safety-details__certificates">
        {globalGapCertificate &&
          <DetailsInformationCard
            cardHeader={t('shared.product.order_food_safety_certifications.global_gap_certificate')}
            detailsData={[{
              label: t('shared.product.order_food_safety_certifications.global_gap_certificate'),
              data: t("bidding_process.required")
            }]}
          />
        }
        {ppecbCertificate &&
          <DetailsInformationCard
            cardHeader={t('shared.product.order_food_safety_certifications.ppecb_certificate')}
            detailsData={[{
              label: t('shared.product.order_food_safety_certifications.ppecb_certificate'),
              data: t("bidding_process.required")
            }]}
          />
        }
        {gaccCertificate &&
          <DetailsInformationCard
            cardHeader={t('shared.product.order_food_safety_certifications.gacc_certificate')}
            detailsData={[{
              label: t('shared.product.order_food_safety_certifications.gacc_certificate'),
              data: t("bidding_process.required")
            }]}
          />
        }
        {mrlResults &&
          <DetailsInformationCard
            cardHeader={t('shared.product.order_food_safety_certifications.mrl_results')}
            detailsData={[{
              label: t('shared.product.order_food_safety_certifications.mrl_results'),
              data: t("bidding_process.required")
            }]}
          />
        }
        {esgCertificate &&
          <DetailsInformationCard
            cardHeader={t('shared.product.order_food_safety_certifications.esg_certificate')}
            detailsData={[{
              label: t('shared.product.order_food_safety_certifications.esg_certificate'),
              data: t("bidding_process.required")
            }]}
          />
        }
        {organic &&
          <DetailsInformationCard
            cardHeader={t('shared.product.order_food_safety_certifications.organic')}
            detailsData={[{
              label: t('shared.product.order_food_safety_certifications.organic'),
              data: t("bidding_process.required")
            }]}
          />
        }
        {pasteurized &&
          <DetailsInformationCard
            cardHeader={t('shared.product.order_food_safety_certifications.pasteurized')}
            detailsData={[{
              label: t('shared.product.order_food_safety_certifications.pasteurized'),
              data: t("bidding_process.required")
            }]}
          />
        }
      </div>
    </div>
  );
};

OrderFoodSafetyDetails.propTypes = {

};

export default OrderFoodSafetyDetails;
