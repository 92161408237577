import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Button from 'components/BaseComponents/Button';
import Input from 'components/BaseComponents/Input';
import Toastify from 'components/Shared/ToastNotification/Toastify';
import { handleServerValidationErrors, requiredField } from 'utils/helper/Validations';
import { processPortalFormSuccess } from 'utils/helper/FormHandlers';
import { errorToastConfig } from 'utils/constants/ToastConfig';
import { BUSINESS_DETAILS_FORM_KEY } from 'utils/constants/portals';
import { useDraftMutation, useGetDraftQuery, useGetUserQuery, useUpdateUserMutation } from 'api/users';
import { hasSubscriptionMemberRole } from 'utils/helper/UserRoleValidation';
import { generateProfileDraftBody } from 'utils/helper/RequestBody';
import { mergeResponce } from 'utils/helper/ObjectKeyConverter';
import './style.scss';

const PersonalDetailsForm = ({ userId, isEditable, toastRef, onTabChange }) => {
  const draftName = "PersonalDetailsForm"
  const { t } = useTranslation();
  const { data: userDetails = {} } = useGetUserQuery({ id: userId });
  const { data: personalDetailsDraft = {} } = useGetDraftQuery({ id: userId, draftName });
  const isFormSaved = !!userDetails?.mobile_number;
  const initialValues = isFormSaved ? userDetails : mergeResponce(personalDetailsDraft.payload, userDetails);

  const [updateUser] = useUpdateUserMutation();
  const [updateDraft] = useDraftMutation();

  const isSubscriptionMember = hasSubscriptionMemberRole(userDetails);

  const handleUserDetailsUpdate = (payload) =>
    updateUser({ userId: userDetails.id, payload })
      .unwrap()
      .then(() => {
        onTabChange(BUSINESS_DETAILS_FORM_KEY);
        processPortalFormSuccess(toastRef, 1);
      })
      .catch(({ data: { errors }, status }) =>
        handleServerValidationErrors(toastRef, status, errors),
      );

  const saveAsDraft = (values) => {
    const payload = generateProfileDraftBody(values, draftName);

    updateDraft({ id: userId, payload })
      .unwrap()
      .then(() => {
        onTabChange(BUSINESS_DETAILS_FORM_KEY);
        processPortalFormSuccess(toastRef, 1);
      })
      .catch(({ data: { errors }, status }) =>
        handleServerValidationErrors(toastRef, status, errors),
      );
  }

  return (
    <div className="personal-details">
      <h3 className="personal-details__title">{t('profile.personal_details.title')}</h3>
      <Form
        onSubmit={handleUserDetailsUpdate}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, hasValidationErrors, values }) => (
          <form
            onSubmit={(event) => {
              if (hasValidationErrors) {
                toastRef?.current?.notify(<Toastify type="error" />, errorToastConfig);
              }
              handleSubmit(event);
            }}
            className="margin-y-32px margin-x-24px"
          >
            <fieldset disabled={!isEditable}>
              <p className="personal-details__form--title">
                {t('profile.personal_details.form.title')}
              </p>
              <div className="grid">
                <Field name="first_name" validate={requiredField}>
                  {({ input, meta }) => (
                    <Input
                      label={t('profile.personal_details.form.first_name.label')}
                      placeholder={t('profile.personal_details.form.first_name.placeholder')}
                      required
                      className="col-6 col-bleed margin-t-16px personal-details__form--col1"
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      value={input.value}
                      onChange={input.onChange}
                      disabled={!isEditable}
                    />
                  )}
                </Field>
                <Field name="last_name" validate={requiredField}>
                  {({ input, meta }) => (
                    <Input
                      label={t('profile.personal_details.form.last_name.label')}
                      placeholder={t('profile.personal_details.form.last_name.placeholder')}
                      required
                      size="small"
                      className="col-6 col-bleed margin-t-16px personal-details__form--col2"
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      value={input.value}
                      onChange={input.onChange}
                      disabled={!isEditable}
                    />
                  )}
                </Field>
              </div>
              <Field name="company_role" validate={isSubscriptionMember ? null : requiredField}>
                {({ input, meta }) => (
                  <Input
                    label={t('profile.personal_details.form.company_role.label')}
                    placeholder={t('profile.personal_details.form.company_role.placeholder')}
                    required={!isSubscriptionMember}
                    size="small"
                    className="margin-t-20px"
                    error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                    touched={meta.touched}
                    value={input.value}
                    onChange={input.onChange}
                    disabled={!isEditable}
                  />
                )}
              </Field>
              <Field name="email">
                {({ input, meta }) => (
                  <Input
                    label={t('profile.personal_details.form.email.label')}
                    placeholder={t('profile.personal_details.form.email.placeholder')}
                    required
                    size="small"
                    className="margin-t-20px"
                    error={meta?.error}
                    touched={meta.touched}
                    value={input.value}
                    onChange={input.onChange}
                    disabled
                  />
                )}
              </Field>
              <div className="grid">
                <Field name="mobile_number" validate={requiredField}>
                  {({ input, meta }) => (
                    <Input
                      label={t('profile.personal_details.form.mobile_number.label')}
                      placeholder={t('profile.personal_details.form.mobile_number.placeholder')}
                      required
                      className="col-6 col-bleed margin-t-20px personal-details__form--col1"
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      value={input.value}
                      onChange={input.onChange}
                      disabled={!isEditable}
                    />
                  )}
                </Field>
                <Field name="telephone_number">
                  {({ input, meta }) => (
                    <Input
                      label={t('profile.personal_details.form.telephone.label')}
                      placeholder={t('profile.personal_details.form.telephone.placeholder')}
                      required={false}
                      size="small"
                      className="col-6 col-bleed margin-t-20px personal-details__form--col2"
                      error={meta?.error || meta?.submitError}
                      touched={meta.touched}
                      value={input.value}
                      onChange={input.onChange}
                      disabled={!isEditable}
                    />
                  )}
                </Field>
              </div>
              {isEditable && (
                <Button
                  className="personal-details__submit-btn"
                  label={t('profile.submit')}
                  submitType="submit"
                  type="success"
                  disabled={submitting}
                />
              )}
              {isEditable && !isFormSaved && (
                <Button
                className="personal-details__draft-btn"
                label="Save as draft"
                onClick={() => saveAsDraft(values)}
                type="default"
                disabled={submitting}
                />
              )}
            </fieldset>
          </form>
        )}
      />
    </div>
  );
};

PersonalDetailsForm.propTypes = {
  userId: PropTypes.number.isRequired,
  isEditable: PropTypes.bool,
  toastRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  onTabChange: PropTypes.func,
};

PersonalDetailsForm.defaultProps = {
  isEditable: true,
  toastRef: null,
  onTabChange: () => {},
};

export default PersonalDetailsForm;
