import React from 'react';
import { t } from 'i18next';
import { gaccCertificate } from 'utils/constants/listingCreation';
import { optionalSectionHeading } from 'utils/helper/LabelWithHints';
import TestCertificates from '../TestCertificates';

const GaccCertificateDocuments = () => (
  <div className="offer-food-safety-padding">
    <h5 className="business-details__section-header">
      {optionalSectionHeading(t('offer_creation.food_safety.gacc_certificate_title'))}
    </h5>

    <div className="flex flex-col">
      <TestCertificates testType={gaccCertificate} isOptional={true}/>
    </div>
  </div>
);

export default GaccCertificateDocuments;
