import { t } from 'i18next';
import {
  chemicalTestTableContents,
  foodSafetyTestSubstancesValues,
  microbiologicalTestTableContents,
} from 'utils/constants/listingCreation';
import { toSnakecaseKeys } from './ObjectKeyConverter';
import {
  generateFoodSafetyTestTableDefaultValues,
  transformFoodSafetyTestCertificateResponse,
} from './listingCreation';

const companyContactResponseBody = (response) => {
  const { directors, authorized_person, trade_references } = response;
  const directorWithFile = directors.map((director) => ({
    ...director,
    id_document: { name: director.id_document?.filename, url: director.id_document?.url },
  }));

  if (directorWithFile.length === 0) {
    directorWithFile.push({});
  }
  while (trade_references.length !== 5) {
    trade_references.push({});
  }

  const companyResolutionFile = authorized_person?.company_resolution?.filename;
  const idDocumentFile = authorized_person?.id_document?.filename;
  const signedLetter = authorized_person?.signed_letter?.filename;
  const companyResolutionFileUrl = authorized_person?.company_resolution?.url;
  const idDocumentFileUrl = authorized_person?.id_document?.url;
  const signedLetterUrl = authorized_person?.signed_letter?.url;

  return {
    ...response,
    directors: directorWithFile,
    authorized_person: {
      ...authorized_person,
      company_resolution: companyResolutionFile && {
        name: companyResolutionFile,
        url: companyResolutionFileUrl,
      },
      id_document: idDocumentFile && { name: idDocumentFile, url: idDocumentFileUrl },
      signed_letter: signedLetter && { name: signedLetter, url: signedLetterUrl },
    },
  };
};

const foodSafetyResponseBody = (response) => {
  const {
    id,
    regulatory_approvals,
    safety_data_sheet,
    safety_document,
    safety_process_diagram,
    total_diet_study,
    ppecb_company_certificate,
    global_gap_company_certificate,
    gacc_company_certificate,
    mrl_company_results,
    esg_company_certificate,
    organic_certificate,
  } = response;

  return {
    id,
    regulatory_approvals: {
      name: regulatory_approvals?.filename,
      url: regulatory_approvals?.url,
      id: regulatory_approvals?.id,
    },
    safety_data_sheet: {
      name: safety_data_sheet?.filename,
      url: safety_data_sheet?.url,
      id: safety_data_sheet?.id,
    },
    safety_document: {
      name: safety_document?.filename,
      url: safety_document?.url,
      id: safety_document?.id,
    },
    safety_process_diagram: {
      name: safety_process_diagram?.filename,
      url: safety_process_diagram?.url,
      id: safety_process_diagram?.id,
    },
    total_diet_study: {
      name: total_diet_study?.filename,
      url: total_diet_study?.url,
      id: total_diet_study?.id,
    },
    ppecb_company_certificate: {
      name: ppecb_company_certificate?.filename,
      url: ppecb_company_certificate?.url,
      id: ppecb_company_certificate?.id,
    },
    global_gap_company_certificate: {
      name: global_gap_company_certificate?.filename,
      url: global_gap_company_certificate?.url,
      id: global_gap_company_certificate?.id,
    },
    gacc_company_certificate: {
      name: gacc_company_certificate?.filename,
      url: gacc_company_certificate?.url,
      id: gacc_company_certificate?.id,
    },
    mrl_company_results: {
      name: mrl_company_results?.filename,
      url: mrl_company_results?.url,
      id: mrl_company_results?.id,
    },
    esg_company_certificate: {
      name: esg_company_certificate?.filename,
      url: esg_company_certificate?.url,
      id: esg_company_certificate?.id,
    },
    organic_certificate: {
      name: organic_certificate?.filename,
      url: organic_certificate?.url,
      id: organic_certificate?.id,
    },
  };
};

const businessDetailsErrorBody = (errors) => ({
  ...errors,
  domicile_address_attributes_zip_code: errors?.domicile_address_zip_code,
  address_attributes_zip_code: errors?.address_zip_code,
  domicile_address_attributes_line_1: errors?.domicile_address_line1,
  domicile_address_attributes_line_2: errors?.domicile_address_line2,
  address_attributes_line_1: errors?.address_line1,
  address_attributes_line_2: errors?.address_line2,
  domicile_address_attributes_city: errors?.domicile_address_city,
  address_attributes_city: errors?.address_city,
});

const convertBusinessDetailsResponse = (response) => {
  const {
    address,
    domicile_address,
    registration_certificate: existing_registration_certificate,
    company_customs_certificate: existing_company_customs_certificate,
    incorporation_certificate: existing_incorporation_certificate,
  } = response;

  const {
    line1 = null,
    line2 = null,
    city = null,
    zip_code = null,
    country_code = null,
  } = address || {};
  const {
    line1: domicile_line1,
    line2: domicile_line2,
    city: domicile_city,
    zip_code: domicile_zip_code,
    country_code: domicile_country_code,
  } = domicile_address || {};

  const { filename: registration_certificate, url: registration_certificate_url } =
    existing_registration_certificate || {};
  const { filename: company_customs_certificate, url: company_customs_certificate_url } =
    existing_company_customs_certificate || {};
  const { filename: incorporation_certificate, url: incorporation_certificate_url } =
    existing_incorporation_certificate || {};

  delete response.address;
  delete response.domicile_address;

  return {
    ...response,
    domicile_address_attributes_line_1: domicile_line1,
    domicile_address_attributes_line_2: domicile_line2,
    domicile_address_attributes_city: domicile_city,
    domicile_address_attributes_zip_code: domicile_zip_code,
    domicile_address_attributes_country_code: domicile_country_code,

    address_attributes_line_1: line1,
    address_attributes_line_2: line2,
    address_attributes_city: city,
    address_attributes_zip_code: zip_code,
    address_attributes_country_code: country_code,

    registration_certificate: registration_certificate && {
      name: registration_certificate,
      url: registration_certificate_url,
    },
    company_customs_certificate: company_customs_certificate && {
      name: company_customs_certificate,
      url: company_customs_certificate_url,
    },
    incorporation_certificate: incorporation_certificate && {
      name: incorporation_certificate,
      url: incorporation_certificate_url,
    },
  };
};

const convertBusinessDetailsDraftPayload = (payload) => (
  {
    ...payload,
    address_attributes_line_1: payload?.address_attributes_line1,
    address_attributes_line_2: payload?.address_attributes_line2,
    domicile_address_attributes_line_1: payload?.domicile_address_attributes_line1,
    domicile_address_attributes_line_2: payload?.domicile_address_attributes_line2,
  }
)

const convertOfferFoodSafetyResponse = (response) => {
  const defaultTestMethodValues = generateFoodSafetyTestTableDefaultValues([
    ...microbiologicalTestTableContents,
    ...chemicalTestTableContents,
  ]);

  const transformFoodSafetyTestTableResponse = (backendResponse, substanceValue) => {
    if (backendResponse) {
      return {
        value: backendResponse.method,
        label: t(`offer_creation.food_safety.test_methods.${backendResponse.method}.method`),
        maxLimit: t(`offer_creation.food_safety.test_methods.${backendResponse.method}.max_limit`),
      };
    }

    // for optional fields
    if (!substanceValue) {
      return null;
    }

    const defaultTestMethodValueOfSubstance = defaultTestMethodValues[substanceValue];

    return {
      value: defaultTestMethodValueOfSubstance,
      label: t(
        `offer_creation.food_safety.test_methods.${defaultTestMethodValueOfSubstance}.method`,
      ),
      maxLimit: t(
        `offer_creation.food_safety.test_methods.${defaultTestMethodValueOfSubstance}.max_limit`,
      ),
    };
  };

  return {
    productId: response.id ? response.id : null,
    organic: response.organic?.toString(),
    want_access_to_pasteurization: response.access_to_pasteurization?.toString(),
    consignment_value: response.pasteurized?.toString(),
    num_of_batch: response.batch,
    e_coli_test_method: transformFoodSafetyTestTableResponse(
      response.e_coli,
      foodSafetyTestSubstancesValues.E_COLI,
    ),
    e_coli_test_value: response.e_coli?.value,
    salmonella_test_method: transformFoodSafetyTestTableResponse(
      response.salmonella,
      foodSafetyTestSubstancesValues.SALMONELLA,
    ),
    salmonella_test_value: response.salmonella?.value,
    plate_count_test_method: transformFoodSafetyTestTableResponse(
      response.plate_count,
      foodSafetyTestSubstancesValues.PLATE_COUNT,
    ),
    plate_count_test_value: response.plate_count?.value,
    yeast_test_method: transformFoodSafetyTestTableResponse(
      response.yeast,
      foodSafetyTestSubstancesValues.YEAST,
    ),
    yeast_test_value: response.yeast?.value,
    mould_test_method: transformFoodSafetyTestTableResponse(
      response.mould,
      foodSafetyTestSubstancesValues.MOULD,
    ),
    mould_test_value: response.mould?.value,
    coliforms_0_4_test_method: transformFoodSafetyTestTableResponse(
      response.coliforms04,
      foodSafetyTestSubstancesValues.CALIFORMS_0_4,
    ),
    coliforms_0_4_test_value: response.coliforms04?.value,
    coliforms_5_8_test_method: transformFoodSafetyTestTableResponse(
      response.coliforms58,
      foodSafetyTestSubstancesValues.CALIFORMS_5_8,
    ),
    coliforms_5_8_test_value: response.coliforms58?.value,
    lysteria_monocytogenes_test_method: transformFoodSafetyTestTableResponse(
      response.lysteria_monocytogenes,
      foodSafetyTestSubstancesValues.LYSTERIA_MONOCYTOGENES,
    ),
    lysteria_monocytogenes_test_value: response.lysteria_monocytogenes?.value,
    staphylococcus_aureus_test_method: transformFoodSafetyTestTableResponse(
      response.staphylococcus_aureus,
      foodSafetyTestSubstancesValues.STAPHYLOCOCCUS_AUREUS,
    ),
    staphylococcus_aureus_test_value: response.staphylococcus_aureus?.value,
    enterobacteria_test_method: transformFoodSafetyTestTableResponse(
      response.enterobacteria,
      foodSafetyTestSubstancesValues.ENTEROBACTERIA,
    ),
    enterobacteria_test_value: response.enterobacteria?.value,
    b_cereus_test_method: transformFoodSafetyTestTableResponse(
      response.b_cereus,
      foodSafetyTestSubstancesValues.B_CEREUS,
    ),
    b_cereus_test_value: response.b_cereus?.value,
    total_aflatoxin_test_method: transformFoodSafetyTestTableResponse(
      response.total_aflatoxin,
      foodSafetyTestSubstancesValues.TOTAL_AFLATOXIN,
    ),
    total_aflatoxin_test_value: response.total_aflatoxin?.value,
    aflatoxin_b1_test_method: transformFoodSafetyTestTableResponse(
      response.aflatoxin_b1,
      foodSafetyTestSubstancesValues.AFLATOXIN_B1,
    ),
    aflatoxin_b1_test_value: response.aflatoxin_b1?.value,
    free_fatty_acid_test_method: transformFoodSafetyTestTableResponse(
      response.free_fatty_acid,
      foodSafetyTestSubstancesValues.FREE_FATTY_ACID,
    ),
    free_fatty_acid_test_value: response.free_fatty_acid?.value,
    peroxide_value2_test_method: transformFoodSafetyTestTableResponse(
      response.peroxide_value2_year,
      foodSafetyTestSubstancesValues.PEROXIDE_VALUE_2_YEAR,
    ),
    peroxide_value2_test_value: response.peroxide_value2_year?.value,
    peroxide_value1_test_method: transformFoodSafetyTestTableResponse(
      response.peroxide_value1_year,
      foodSafetyTestSubstancesValues.PEROXIDE_VALUE_1_YEAR,
    ),
    peroxide_value1_test_value: response.peroxide_value1_year?.value,
    // optional
    lead_test_method: transformFoodSafetyTestTableResponse(response.lead),
    lead_test_value: response.lead?.value,
    mercury_test_method: transformFoodSafetyTestTableResponse(response.mercury),
    mercury_test_value: response.mercury?.value,
    cadmium_test_method: transformFoodSafetyTestTableResponse(response.cadmium),
    cadmium_test_value: response.cadmium?.value,
    arsenic_test_method: transformFoodSafetyTestTableResponse(response.arsenic),
    arsenic_test_value: response.arsenic?.value,
    // certificates
    microbiological_certificate: transformFoodSafetyTestCertificateResponse(
      response.microbiological_test_certificates,
    ),
    chemical_certificate: transformFoodSafetyTestCertificateResponse(
      response.chemical_test_certificates,
    ),
    heavy_metal_certificate: transformFoodSafetyTestCertificateResponse(
      response.metal_level_test_certificates,
    ),
    max_residue_certificate: transformFoodSafetyTestCertificateResponse(
      response.maximum_residue_level_certificates,
    ),
    ppecb_certificate: transformFoodSafetyTestCertificateResponse(response.ppecb_certificates),
    global_gap_certificate: transformFoodSafetyTestCertificateResponse(
      response.global_gap_certificates,
    ),
    gacc_certificate: transformFoodSafetyTestCertificateResponse(response.gacc_certificates),
    mrl_results: transformFoodSafetyTestCertificateResponse(response.mrl_results),
    esg_certificate: transformFoodSafetyTestCertificateResponse(response.esg_certificates),
    listing_organic_certificates: transformFoodSafetyTestCertificateResponse(
      response.listing_organic_certificates,
    ),

    compliance_institute: !response.compliance_institute
      ? 'SAMAC Maximum Residue Limits as of October 2023'
      : response.compliance_institute,
  };
};

const handleErrorsOfferFoodSafety = (message) => {
  const error = {};
  const response = toSnakecaseKeys(message);

  const transformError = (errorBackend, errorFieldFrontend) => {
    if (errorBackend) {
      error[errorFieldFrontend] = errorBackend.join(', ');
    }
  };

  transformError(response.batch, 'num_of_batch');
  transformError(response.e_coli_value, 'e_coli_test_value');
  transformError(response.salmonella_value, 'salmonella_test_value');
  transformError(response.plate_count_value, 'plate_count_test_value');
  transformError(response.yeast_value, 'yeast_test_value');
  transformError(response.mould_value, 'mould_test_value');
  transformError(response.coliforms04_value, 'coliforms_0_4_test_value');
  transformError(response.coliforms58_value, 'coliforms_5_8_test_value');
  transformError(response.lysteria_monocytogenes_value, 'lysteria_monocytogenes_test_value');
  transformError(response.staphylococcus_aureus_value, 'staphylococcus_aureus_test_value');
  transformError(response.enterobacteria_value, 'enterobacteria_test_value');
  transformError(response.b_cereus_value, 'b_cereus_test_value');

  transformError(response.total_aflatoxin_value, 'total_aflatoxin_test_value');
  transformError(response.aflatoxin_b1_value, 'aflatoxin_b1_test_value');
  transformError(response.free_fatty_acid_value, 'free_fatty_acid_test_value');
  transformError(response.peroxide_value2_year_value, 'peroxide_value2_test_value');
  transformError(response.peroxide_value1_year_value, 'peroxide_value1_test_value');

  transformError(response.lead_value, 'lead_test_value');
  transformError(response.mercury_value, 'mercury_test_value');
  transformError(response.cadmium_value, 'cadmium_test_value');
  transformError(response.arsenic_value, 'arsenic_test_value');
  transformError(response.organic, 'organic');

  return error;
};

export {
  companyContactResponseBody,
  foodSafetyResponseBody,
  businessDetailsErrorBody,
  convertBusinessDetailsResponse,
  convertOfferFoodSafetyResponse,
  handleErrorsOfferFoodSafety,
  convertBusinessDetailsDraftPayload,
};
